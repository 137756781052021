<div class="modulo modulo-destaques">
      <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
            <div class="container">
                  <h2 class="section-title-h2">{{data.titulo}}</h2>
                  <h3 class="section-title-h3" *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h3>
            </div>
      </div> 
      <div class="modulo-content">
            <div class="container">
                  <div class="row">
                        <div class="col-md-3" *ngFor="let d of data.data">
                              <div class="item">
                                    <div class="capa">       
                                          <img [src]="d.imagem" [alt]="d.titulo" width="100%" />
                                    </div>
                                    <div class="content">  
                                          <h4>{{d.titulo}}</h4>
                                          <div class="content-button" *ngIf="d.link_ativo">
                                                <a [href]="d.link" [target]="d.link_target">
                                                      <span>Saiba Mais</span>
                                                </a>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
      </div>      
</div>      